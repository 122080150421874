import { deviceClassifyService } from '@/api';
import { ApiResponse } from '@/api/axios';
import { Paging } from '@/api/base';
import supplierDeviceService from '@/api/supplier-management/supplier-deivce';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { dateFormat } from '@/filters';
import { DeviceStatusEnum } from '@/resource/enum';
import { SupplierDeviceResource, ImportRes } from '@/resource/model';
import { translation, showWarningConfirm, downloadFileByBlob, handleImportError, messageError } from '@/utils';
import { ImportFile } from '@/views/dialogs';
import { Message } from 'element-ui';
import { Component, Watch, Vue } from 'vue-property-decorator';
import AddSupplierDevice from './add-supplier-device/add-supplier-device.vue';

@Component({
  name: 'SupplierDevice',
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    ImportFile,
    AddSupplierDevice
  }
})
export default class SupplierDevice extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<SupplierDeviceResource> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };
  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<SupplierDeviceResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'deviceName',
      label: 'supplierDevice.deviceName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'supplierDevice.categoryName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'printHead',
      label: 'supplierDevice.printHead',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'colorInfo',
      label: 'supplierDevice.colorInfo',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '200px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 查询表单数据对象
   */
  public queryForm: Partial<{
    keywords: string;
    categoryId: number | null;
    status: DeviceStatusEnum | null;
    companyId: number;
  }> = {
    keywords: '',
    categoryId: null,
    status: null
  };
  /**
   * 表格查询表单配置
   */
  public queryFormOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'categoryId',
      label: 'supplierDevice.category',
      option: {
        placeholder: 'supplierDevice.selectCategory'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: (): Array<any> => {
        return [
          {
            label: translation('common.startUsing'),
            value: 1
          },
          {
            label: translation('common.disabled'),
            value: 2
          },
          {
            label: translation('common.repair'),
            value: 3
          }
        ];
      }
    }
  ];
  /**
   * 表格操作栏
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'supplier:device:save',
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'supplier:device:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:device:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'supplier:device:exportData',
      loading: false,
      handleClick: (): void => {
        this.export();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      disabled: true,
      permissionCode: 'supplier:device:exportData',
      handleClick: (): void => {
        this.batchUpdateStatus(DeviceStatusEnum.enable);
      }
    },
    {
      type: 'danger',
      slot: 'end',
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:device:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(DeviceStatusEnum.disable);
      }
    },
    {
      type: 'danger',
      slot: 'end',
      label: 'button.repairing',
      operationType: 'repair',
      icon: 'el-icon-turn-off',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:device:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(DeviceStatusEnum.repair);
      }
    }
  ];
  /**
   * 列操作配置
   */
  public rowOperationOption: RowOperation<SupplierDeviceResource> = {
    width: '130px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:device:edit',
        handleClick: (row: SupplierDeviceResource): void => {
          this.openDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:device:delete',
        handleClick: (row: SupplierDeviceResource): void => {
          this.delete(row);
        }
      }
    ]
  };
  /**
   * 分页配置
   */
  public pagintaionOption: Paging = {
    currentPage: 1,
    showCount: 10
  };
  /**
   * 表格总记录数
   */
  public totalData = 0;
  /**
   * dialog显示状态
   */
  public dialogVisible = false;
  /**
   * 编辑数据对象
   */
  public editRow: SupplierDeviceResource | null = null;
  /**
   * 导入dialog显示状态控制
   */
  public importDialogVisible = false;
  /**
   * 导入dialog 标题
   */
  public importTitle = 'supplierDevice.importDevice';
  /**
   * 表格选中数据
   */
  private selectedRows: Array<SupplierDeviceResource> = [];

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadDeviceCategories();
    this.loadData();
  }

  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 分页事件
   */
  public handlePagination(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(rows: Array<SupplierDeviceResource>): void {
    this.selectedRows = rows;
  }

  /**
   * 加载表格数据
   */
  public loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    supplierDeviceService
      .getList(this.queryForm as SupplierDeviceResource, this.pagintaionOption)
      .then((res: { data: SupplierDeviceResource[]; total: number }) => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 重新加载
   */
  public reloadData(): void {
    this.pagintaionOption.currentPage = 1;
    (this.$refs.deviceTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public getStatusClass(status: number): string {
    return supplierDeviceService.getDeviceStatusClass(status);
  }

  public getStatusI18Key(status: number): string {
    return supplierDeviceService.getDeviceStatusI18Key(status);
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.editRow = null;
  }
  /**
   * 编辑保存成功事件
   * 更新表格数据
   */
  public handleEditSuccess(data: SupplierDeviceResource): void {
    const findItem = this.tableOption.data.find(row => row.id === data.id);
    Object.assign(findItem, data);
  }
  /**
   * 批量更新状态
   * @param status 状态
   */
  public batchUpdateStatus(status: DeviceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    supplierDeviceService
      .batchUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(translation('common.statusUpdateStatus'));
      })
      .catch(error => {
        messageError(error);
      });
  }
  /**
   * 下载导入模板
   */
  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    supplierDeviceService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob(translation('supplierDevice.deviceTemplate'), blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  /**
   * 上传成功事件
   */
  public handleImportSuccess(path: string): void {
    const companyId = Number(this.$route.query.companyId);
    supplierDeviceService
      .import(companyId, path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<SupplierDeviceResource>): void {
    const allowedOpationArray = ['add', 'import', 'export'];
    this.tableToolbarOptions.forEach(button => {
      if (allowedOpationArray.indexOf(button.operationType) === -1) {
        button.disabled = value.length === 0;
      }
    });
    this.tableToolbarOptions = [...this.tableToolbarOptions];
  }
  /**
   * 删除
   */
  private delete(row: SupplierDeviceResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await supplierDeviceService.delete(row.id);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reloadData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 批量删除
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList = this.selectedRows.map(row => row.id);
          await supplierDeviceService.batchDelete(idList);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reloadData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 打开dialog
   * @param row 编辑数据
   */
  private openDialog(row: SupplierDeviceResource | null = null): void {
    this.editRow = row;
    this.dialogVisible = true;
  }
  /**
   * 打开导入窗口
   */
  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  /**
   * 获取材料分类
   */
  private async loadDeviceCategories(): Promise<void> {
    const categoryList = await deviceClassifyService.listAll();

    const queryOptionItem = this.queryFormOptions.find(option => option.field === 'categoryId');

    if (queryOptionItem) {
      queryOptionItem.optionData = categoryList.map(category => {
        return {
          label: category.categoryName,
          value: category.id
        };
      });
    }
  }

  /**
   * 导出数据
   */
  private async export(): Promise<void> {
    const option = this.tableToolbarOptions.find(option => option.operationType === 'export');
    option!.loading = true;

    const exportCondition = {
      ...this.queryForm,
      idList: this.selectedRows.map(row => row.id)
    } as any;

    try {
      const blob = await supplierDeviceService.export(exportCondition);
      downloadFileByBlob(
        `${translation('supplierDevice.exportDevice')}-${dateFormat(new Date(), 'YYYYMMDDHHmmss')}.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error);
    } finally {
      option!.loading = false;
    }
  }
}
